import { useBreakpointIndex } from '@theme-ui/match-media';
import { GatsbyImage } from 'gatsby-plugin-image';
import { useAtomValue } from 'jotai';
import { Dispatch, SetStateAction, useEffect, useRef } from 'react';
import ReactSlickSlider, { Settings } from 'react-slick';

import {
  Box,
  Flex,
  Heading,
  Link,
  Paragraph,
  SliderNextArrow,
  SliderPrevArrow,
  Spacer,
  Text,
} from 'voom-gatsby';

import { ConsultationTag } from '~components';

import { mobileResultsViewAtom } from '~hooks/use-doctor-results';

import { getMapBounds } from '~utils/map';

import { DoctorResult } from '~types/sanity';

interface DoctorResultCarouselProps {
  doctors: DoctorResult[];
  selectedIndex: number | null;
  setSelectedIndex: Dispatch<SetStateAction<number | null>>;
  googleMapInstance: google.maps.Map | null;
}

export const DoctorResultsCarousel = ({
  doctors,
  selectedIndex,
  setSelectedIndex,
  googleMapInstance,
}: DoctorResultCarouselProps) => {
  const ref = useRef<ReactSlickSlider>(null);
  const mobileView = useAtomValue(mobileResultsViewAtom);
  const breakpointIndex = useBreakpointIndex();

  const doctorResultCarousel: Settings = {
    dots: false,
    infinite: false,
    slidesToScroll: 1,
    slidesToShow: 1,
    prevArrow: <SliderPrevArrow variant="slickArrowSm" sx={{ left: 2 }} />,
    nextArrow: <SliderNextArrow variant="slickArrowSm" sx={{ right: 2 }} />,
    beforeChange: (_, next) => {
      setSelectedIndex(next);
      googleMapInstance &&
        getMapBounds(googleMapInstance, [doctors[next].geolocation]);

      // zoom in on map
      mobileView === 'map' && googleMapInstance?.setZoom(12);
    },
  };

  useEffect(() => {
    selectedIndex && ref.current?.slickGoTo(selectedIndex);
  }, [selectedIndex]);

  return (
    <ReactSlickSlider
      // Typescript errors, but it works. needed to use slickGoTo
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      ref={ref}
      {...doctorResultCarousel}
      sx={{
        // only render on mobile
        display: ['block', null, null, 'none'],
        position: 'absolute',
        bottom: '24px',
        left: 0,
        right: 0,

        '.slick-list': {
          '.slick-slide': {
            mx: 4,
          },
        },
      }}
    >
      {doctors.map((doctor, index) => {
        return (
          <Link
            to={`/find-a-doctor/doctors/${doctor.slug.current}`}
            key={doctor.name + index}
            variant="ghost"
            sx={{
              borderRadius: 'corner',
              overflow: 'hidden',
              display: 'grid !important',
              bg: 'white',
              aspectRatio: '320 / 122',
              width: 'full',
              gridTemplateColumns: '55fr 45fr',
              isolation: 'isolate',
            }}
          >
            <GatsbyImage
              image={doctor.image.asset.gatsbyImageData}
              alt={doctor.image.alt}
              sx={{
                img: {
                  objectPosition: 'top',
                },
              }}
            />
            <Box sx={{ flex: '0 0 45%', p: 3 }}>
              <Paragraph
                variant="copyright"
                sx={{ color: 'black', fontSize: ['copyright', 'sm'] }}
              >{`${doctor.city.name}, ${doctor.state.abbreviation}`}</Paragraph>
              <Spacer space={[1, null, 2]} />
              <Heading sx={{ fontSize: ['xs', 'md', 'xl'] }}>
                {doctor.name}
              </Heading>
              <Spacer space={1} />
              <Paragraph variant="copyright">
                {doctor.credentials && <Text>{doctor.credentials}</Text>}
                {doctor.proficiency && (
                  <>
                    <Text
                      sx={{
                        mx: 2,
                        borderRight: (theme) =>
                          `1px solid ${theme.colors?.grey300}`,
                      }}
                    />
                    <Text>{doctor.proficiency}</Text>
                  </>
                )}
              </Paragraph>
              <Spacer space={[1, null, 3]} />
              <Flex>
                {doctor.consultations.map((consultation) => (
                  <ConsultationTag
                    key={consultation}
                    consultation={consultation}
                    withText={breakpointIndex > 1}
                    sx={{
                      bg: 'tan',
                      mr: 2,
                    }}
                  />
                ))}
              </Flex>
            </Box>
          </Link>
        );
      })}
    </ReactSlickSlider>
  );
};
